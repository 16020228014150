import $ from 'jquery';

import * as THREE from 'three';

//Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';

import { Cameras } from './cameras';
import { Lights } from './lights';
import { Objects } from './objects.js';
import { Particules } from './particules';
import { Text } from './text.js';

/**
 * @class La classe qui va générer une vidéo à partir d’une scène ThreeJs.
 */
class Video 
{
    /**
     * Constructeur de la classe Video
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     * @param {String} session_id L’idientifiant de la session.
     * @param {Text} text Gestionnaire du message à rendre.
     * @param {Lights} lights Gestionnaire des lumières à rendre.
     * @param {Objects} objects Gestionnaire de l’objet à afficher.
     * @param {Cameras} cameras Gestionnaire de la caméra.
     * @param {Particules} particules Gestionnaire des effets de particules (ce 
     * sont des vidéos placées en arrière plan.)
     */
    constructor(scene, gui, session_id, text, lights, objects, cameras, particules)
    {
        this.scene = scene;
        this.gui = gui;
        this.session_id = session_id;
        this.text = text;
        this.lights = lights;
        this.objects = objects;
        this.cameras = cameras;
        this.particules = particules;

        /** Raccourcis pour accéder à la caméra de la scène.
         * @type {THREE.Camera}
         */
        this.camera = this.cameras.camera;

        /** Durée de la vidéo en secondes.
         * @type {Float}
        */
        this.video_lenght = 8;

        /** Le pourcentage d’avancement de la génération de la vidéo. 
         * @type {Float}
        */
        this.progression = 0.0;

        /** L’interface affichant la progression de la génération de la vidéo.
         * @type {GUi.Controller}
         */
        this.progression_gui = null;

        /** Le bouton permettant d’exécuter la génération de la vidéo. */
        this.rendering_button = null;

        /** Le nœud de l’interafce contenant les paramètres de la génération de 
         * la vidéo.
         * @type {GUI}
        */
        this.folder = null;

        /**Le bouton pour télécharger la vidéo qui vient d’être générée.
         * @type {GUI}
        */
        this.download_button = null;

        /** L’ip du serveur. Cela va permettre temporairement de savoir si on
         * est sur le serveur de production qui n’a pas pour le moment de CG.
         * @link https://ibootweb.com/questions/2794338/comment-connaitre-ladresse-ip-du-serveur-en-utilisant-javascript-auquel-le-navigateur-est-connecte 
         * @remarks C’est temporaire. A terme le serveur de production aura lui
         * aussi une carte graphique.
        */
        this.ip = location.host;

        /** Les ip possibles des serveurs locaaux de type LAMP ou WAMP. En comparant avec l'ip on peut savoir si on est sur serveur de teste ou un serveur de production.
        */
        this.ip_locals = ["127.0.0.1", "localhost"];

        /** 
         * - Rapide: lancera Eevee. (qualité 3D temps réelle, moins réaliste)
         * - Lent: lancera cycle avec un faible nombre de passes. (plus réaliste 
         * mais il y a plus d’artefacts)
         * - Très lent: lancera cycle avec un grand nombre de passes. (réaliste et 
         * très très peu d’artefacts)
         * @type {Array<String>}
         */
        this.rendering_qualities = this.is_production_server() ? ["Lent", "Très lent", "Extrème"] : ["Rapide", "Lent", "Très lent", "Extrême"];

        /** La qualité du rendu choisi
         * @see rendering_qualities
         * @type {String}
         */
        this.rendering_quality = this.rendering_qualities[0];

        /** Le temps entre deux mise à jour de la barre de progression de la 
         * création de la vidéo.
         * @type {Float}
         */
        this.delta_time = 1;

        /** Le dernier temps d’enregistré où la barre de progression fût mis à 
         * jour.
         * @type {Float}
         * */
        this.last_time = 0;

        this.setup_scene();
        this.setup_gui();
    }

    /** Création des éléments de la scène pour générer la vidéo.
     * @remarks Ne sert que pour respecter la structures des classes.
     */
    setup_scene()
    { }

    /** Création de l'interface permettant de manipuler les lumières */
    setup_gui()
    {
        this.folder = this.gui.addFolder('Vidéo')

        if(!this.is_production_server())
            this.folder.add(this, 'rendering_quality', this.rendering_qualities).name('Rapidité du rendu');

        this.create_rendering_button();
        this.folder.open();
    }

    create_rendering_button()
    {
        if(this.rendering_button === null)
            this.rendering_button = this.folder.add(this, 'rendering').name('Rendre la vidéo').onChange(() => { });
    }

    /** Retourne un tableau dont:
     * - Le premier élément est un tableau [x, y, z] représentant le vecteur 3
     * de la position de l’objet.
     * - Le deuxième élément est un tableau [x, y, z, w] représentant le 
     * quaternion de l’orientation de l’objet.
     * - Le troisième élément est un tableau [x, y, z] représentant le vecteur 3
     * de l’échelle de l’objet.
     * 
     * @param {THREE.Object3D} object_3d 
     * @returns {Array<Array<float>>} [[pos.x, pos.y, pos.z],
     *                                 [quat.x, quat.y, quat.z, quat.w],
     *                                 [scale.x, scale.y, scale.z]]
     */
    decompose(object_3d)
    {
        object_3d.updateMatrixWorld(true);
        object_3d.updateWorldMatrix(true, true);

        var pos = new THREE.Vector3();
        var quat = new THREE.Quaternion();
        var scale = new THREE.Vector3();

        object_3d.matrixWorld.decompose(pos, quat, scale);

        return [[pos.x, pos.y, pos.z],
        [quat.x, quat.y, quat.z, quat.w],
        [scale.x, scale.y, scale.z]];

    }

    /**
     * Permet de savoir si le serveur est locale ou de production.
     * @returns serveur locale renvoie faux, serveur de production renvoie vrais. 
     */
    is_production_server()
    {
        var result = true;

        for(var index in this.ip_locals)
        {
            var local_ip = this.ip_locals[index];
            if(result && this.ip.includes(local_ip))
                result = false;
        }

        return result;
    }

    /** Lance le génération de la vidéo.
     * On envoyer les paramètres au serveur pour effecture le rendu.
     * @todo Puis qu’elle fait rien, faut-il la remplacer? 
    */
    rendering()
    {
        if(this.progression_gui === null || typeof this.progression_gui == 'undefined')
        {
            var text_transformation = this.decompose(this.text.mesh);

            var object_name = "";
            var object_transformation = [[0, 0, 0,], [0, 0, 0, 1], [1, 1, 1]];

            if(this.objects.has_selected_object()) 
            {
                object_name = this.objects.selected_object_name;
                object_transformation = this.decompose(this.objects.gltf_scene);
            }

            // On récupère la liste des lumières
            var light_types = [];
            var light_names = [];
            var light_positions = [];
            var light_quaternions = [];
            var light_target_positions = [];
            var light_intensities = [];
            var light_colours = [];
            this.scene.traverse((node) =>
            {
                if(node instanceof THREE.Light)
                {
                    light_types.push(node.type);
                    light_names.push(node.name != '' ? node.name : node.id);

                    light_intensities.push(node.intensity);
                    light_colours.push(node.color);

                    var transformation = this.decompose(node);
                    light_positions.push(transformation[0]);
                    light_quaternions.push(transformation[1]);

                    if(node instanceof THREE.DirectionalLight && node.target != null)
                    {
                        var target_transformation = this.decompose(node.target);
                        light_target_positions.push(target_transformation[0]);
                    }
                    else
                    {
                        light_target_positions.push([0, 0, 0]);
                    }
                }
            });

            //var light_set = this.lights.light_sets[this.lights.light_set_name];

            //console.log(this.scene.toJSON());
            //console.log("light_set.name: " + light_set.name);
            //console.log("light_names: " + light_names);
            //console.log("light_types: " + light_types);
            //console.log("light_positions: " + light_positions);
            //console.log("light_quaternions: " + light_quaternions);
            //console.log("light_target_positions: " + light_target_positions);
            //console.log("light_intensities: " + light_intensities);
            //console.log("light_colours: " + light_colours);

            // https://api.jquery.com/jQuery.post/ 
            // https://www.php.net/manual/fr/features.connection-handling.php
            $.post("video_rendering.php", {

                // texte
                // Pour le moment j’ai une CG seulement en local, sinon c’est du 
                // CPU.
                rendering_quality: this.rendering_qualities.indexOf(this.rendering_quality),
                message: this.text.message,
                font_name: this.text.font_name,
                thickness: this.text.thickness,
                text_position: text_transformation[0],
                text_quaternion: text_transformation[1],
                text_scale: text_transformation[2],
                text_animation: this.text.animation_map.indexOf(this.text.animation_choice),
                text_colour: this.text.material.color,
                object_name: object_name,
                object_position: object_transformation[0],
                object_quaternion: object_transformation[1],
                object_scale: object_transformation[2],
                cam_position: [this.camera.position.x, this.camera.position.y, this.camera.position.z],
                cam_fov: this.camera.fov,
                cam_near: this.camera.near,
                cam_far: this.camera.far,
                //light_set_name: light_set.name,
                light_types: light_types,
                light_names: light_names,
                light_positions: light_positions,
                light_quaternions: light_quaternions,
                light_target_positions: light_target_positions,
                light_intensities: light_intensities,
                light_colours: light_colours,
                particles_video_path: this.particules.video_name != this.particules.no_video_name ? this.particules.videos[this.particules.video_name].path : "",
                session_id: this.session_id
            },
                (data, status) =>
                {
                    //alert("Data: " + data + "\nStatus: " + status);
                })
                .fail((jqXHR) =>
                {
                    // Je met en commentaire car le timeout peux se déclencher mais
                    // pour autant le rendu suit son exécution puis la ffmpeg est 
                    // appelé correctement.
                    //alert("Request failed: " + jqXHR.responseText);
                });

            this.progression = 0;
            this.progression_gui = this.folder.add(this, 'progression', 0, 100).name('Progression');

            if(!(this.rendering_button === null))
            {
                this.rendering_button.remove();
                this.rendering_button = null;
            }

            if(!(this.download_button === null))
            {
                this.download_button.remove();
                this.download_button = null;
            }
        }
    }

    /** Permet de télécharger la vidéo générée. */
    download()
    {
        // https://js-tricks.info/javascript/how-to-get-current-url-path-in-javascript/
        var filename = "output.mp4";
        
        /*
        var url = window.location.href + "output/" + this.session_id + "/" + filename;
        /*/
        var url = "output/" + this.session_id + "/" + filename;        
        //*/
        
        Video.download(filename, url);
    };

    /** Permet de télécharger un fichier vidéo via le navigateur internet.
     * @param {String} filename Le nom du fichier avec l’extension: 
     *                          ex "output.mp4".
     * @param {String} url L’adresse internet du fichier à télécharger.
     * @todo implémenter une solution plus éléguante. C’est à dire avec la 
     * fenêtre de dialogue qui s’affiche.
     */
    static download(filename, url)
    {
        //*
        var element = document.createElement('a');
        element.setAttribute('href', 'video_download.php?path=' + encodeURIComponent(url));
        
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        /*/
        //window.open(encodeURIComponent(url),"_blank", null);
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(url));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        //*/
    }

    /** Fonction appelée lors du rendu de chaque frame.
     * @param {Float} time temps depuis le lancement de l’application en 
     * milli-secondes.
     */
    render(time) 
    {
        // Le temps en secondes
        var time_s = time / 1000.0;

        if(!(this.progression_gui === null) && !(typeof this.progression_gui == 'undefined'))
        {
            if(time_s - this.last_time > this.delta_time)
            {
                this.last_time = time_s;

                $.post("video_progression.php", { session_id: this.session_id })
                    .done((data) =>
                    {
                        this.progression = parseFloat(data) * 100;
                        if(!(this.progression_gui === null) && !(typeof this.progression_gui == 'undefined'))
                            this.progression_gui.setValue(this.progression);
                        else
                            console.warn("this.progression_gui est nulle ou " +
                                "indéfinie. La progression ne peut pas être " +
                                "affichée.");
                    })
                    .fail((jqXHR, textStatus, errorThrown) =>
                    {
                        alert("Request failed: " + textStatus + ' ' + errorThrown + ' ' + jqXHR.responseText);
                    });
            }

            if(this.progression >= 100)
            {
                if(this.download_button === null)
                    this.download_button = this.folder.add(this, 'download').name('Télécharger');

                this.progression_gui.remove();
                this.progression_gui = null;

                this.create_rendering_button();
            }
        }
    }
}

export { Video };
