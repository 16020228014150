import {
	Color,
	Vector2
} from 'three';

/**
 * Circle Shader
 * Dessinne un cercle 
 * 
 * diameter: Le diamètre du cercle
 * thickness: L’épaisseur du trait pour dessinner le cercle.
 * resolution: La résolution du viewport.
 * external_color: La couleur qui sera afficher en-dehors du cercle.
 * https://www.shaderific.com/glsl-functions/
 */

const CircleShader = {

	defines:{
		'PI': 3.14159265
	},
	uniforms: {

		'tDiffuse': { value: null },
		'diameter': { value: 480 },
		'thickness': { value: 3 },
		'resolution': { value: new Vector2() },
		'external_color': { value: new Color( 0x0000 )  },
		'time': { value: 0 }
	},

	vertexShader: /* glsl */`

		varying vec2 vUv;

		void main() {

			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

		}`,

	fragmentShader: /* glsl */`

		uniform sampler2D tDiffuse;
		uniform float diameter;
		uniform float thickness;
		uniform vec2 resolution;
		uniform vec3 external_color;
		uniform float time;

		varying vec2 vUv;

		// https://www.shadertoy.com/view/XljXzV
		vec3 hsl2rgb( in vec3 c )
		{
			vec3 rgb = clamp( abs(mod(c.x*6.0+vec3(0.0,4.0,2.0),6.0)-3.0)-1.0, 0.0,1.0);
			return c.z + c.y * (rgb-0.5)*(1.0-abs(2.0*c.z-1.0));
		}

		// https://www.shadertoy.com/view/XljXzV
		float get_hue(in vec2 uv)
		{
			// Position normalised into (-1, 0, 1)
			vec2 d = 1.0 - (uv * 2.0);
			
			// Distance from screen center
			float dist = sqrt((d.x*d.x) + (d.y*d.y));
			
			// Rotation
			float r = acos(d.x / dist);
			if (d.y < 0.0) { r = PI-(r + PI); } // Sort out the bottom half (y=-1)
			
			r += (PI * 1.5 * time / 3600.0); // Rotate (red on top, green on right, blue on left)
			
			// From radians (0 - 2_PI) to hue (0 - 1)
			return ((r / PI) / 2.0);
		}

		void main() 
		{
			vec3 color = texture2D(tDiffuse, vUv).xyz;

			vec2 image_center = resolution * 0.5;
			vec2 pixel_coords = vUv * resolution;
			float pixel_distance = distance(pixel_coords, image_center);
			float radius = diameter * 0.5;

			float hue = get_hue(vUv);
			vec3 border_color = vec3(hsl2rgb( vec3(hue, 1.0, 0.5)));

			// 0 = intérieur du disque
			// 1 = extérieur du disque
			float circle_factor = step(radius, pixel_distance);
			color = circle_factor * external_color + (1.0 - circle_factor) * color;
			
			// 1 = bordure
			// 0 = extérieur de la bordure
			float smooth_thickness = 1.0;
			float border_factor = smoothstep(radius - smooth_thickness, radius + smooth_thickness, pixel_distance) * (1.0 - smoothstep(radius + thickness - smooth_thickness, radius + thickness + smooth_thickness, pixel_distance));
			color = border_factor * border_color + (1.0 - border_factor) * color;

			gl_FragColor = vec4(color, 1.0);
		}`

};

export { CircleShader };
