import $ from 'jquery';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
//Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

/**
 * @class Défini les paramètre d’un objet 3D pour être chargé dans ThreeJs.
 */
class Object
{
    constructor(name, gltf_url)
    {
        /**
         * Le nom de l’objet.
         * @type {String}
         */
        this.name = name;

        /**
         * L’adresse internet de l’objet (.gltf ou .glb) à charger dans TreeJs.
         * @type {String}
         */
        this.gltf_url = gltf_url;
    }
}

/**
 * @class Permet de choisir et de rendre un objet 3D.
 */
class Objects
{
    /** Constructeur de la classe Object
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     */
    constructor(scene, gui)
    {
        this.scene = scene;
        this.gui = gui;

        /** L’interface affichant la liste des objets disponible et celui qui 
         * est sélectionné.
         * @type {GUI}
         */
        this.object_gui = null;

        /** L’intitulé afficher lorsque aucun objet n’est sélectionné.
         * @type {String}
        */
        this.no_object_name = "Aucun";

        /** Le nom de l’objet sélectionné. Par défaut aucun objet n’est 
         * sélectionné. 
         * @type {String}
         */
        this.selected_object_name = this.no_object_name;

        /** Le dictionnaire des objets 
         *  Sera initialisé par l’appel à get_object_names()
        */
        this.objects = {};

        /** Liste des noms des objets disponible. Cette liste sera complémenter par un 
         * appel à objects.php
         * @type {Array<String>}
         */
        this.objects_names = this.get_object_names();

        /** Le nœud contenant l’objet 3D.
         * @type {THREE.Group}
         */
        this.group = null;

        /** La scène représentant l’objet chargé. Si auncun n’est affiché alors  
         * cette variable est nulle.
         * @type {THREE.Scene}
         */
        this.gltf_scene = null;

        /** Le nœud de l’interafce contenant les paramètres du shader. 
         * @type {GUI}
        */
        this.folder = null;

        /** Celui qui va appeler les animations du gltf
         * @type {THREE.AnimationMixer}
         */
        this.gltf_mixer = null;

        /** L’horloge qui permettra au compositeur d’animation de retrouver le
         *  temps écouler depuis la dernière image rendu.
         * @type {THREE.Clock}
         */
        this.clock = null;

        this.setup_scene();
        this.setup_gui();
    }

    /** Création du post traîtement */
    setup_scene()
    {
        this.group = new THREE.Group();
        this.scene.add(this.group);
        this.clock = new THREE.Clock();
    }

    /** Création de l'interface permettant de manipuler la caméra. */
    setup_gui()
    {
        this.folder = this.gui.addFolder('Objet');
        this.selected_object_name = this.objects_names[0];
        this.object_gui = this.folder.add(this, 'selected_object_name', this.objects_names).name("Objet").onChange((value) =>
        {
            this.update_selected_object_name().catch((err) =>
            {
                console.error(err);
            });
        });

        this.folder.add(this.group.scale, 'x', 0.1, 4).name('Échelle').onChange(() =>
        {
            this.group.scale.z = this.group.scale.y = this.group.scale.x;
        });

        const pos_folder = this.folder.addFolder('Position');
        pos_folder.add(this.group.position, 'x', -200, 200);
        pos_folder.add(this.group.position, 'y', -200, 200);
        pos_folder.add(this.group.position, 'z', -200, 200);

        const rot_folder = this.folder.addFolder('Orientation');
        rot_folder.add(this.group.rotation, 'x', -Math.PI, Math.PI);
        rot_folder.add(this.group.rotation, 'y', -Math.PI, Math.PI);
        rot_folder.add(this.group.rotation, 'z', -Math.PI, Math.PI);

        /* 
        // https://github.com/mrdoob/three.js/blob/master/examples/webgl_animation_skinning_morph.html
            actions = {};

            for ( let i = 0; i < animations.length; i ++ ) {

                const clip = animations[ i ];
                const action = mixer.clipAction( clip );
                actions[ clip.name ] = action;

                if ( emotes.indexOf( clip.name ) >= 0 || states.indexOf( clip.name ) >= 4 ) {

                    action.clampWhenFinished = true;
                    action.loop = THREE.LoopOnce;

                }

            }
        */
    }

    /** Retourne la liste des noms des Objets
     * @type {Array<String>}
     */
    get_object_names()
    {
        let result = [this.no_object_name];

        let request = $.ajax({
            type: 'POST',
            url: "objects.php",
            dataType: "json",
            async: false
        });

        request.done((data) =>
        {
            /// [{  
            ///     "name":"coffe_cup",
            ///     "gltf_url":"http://127.0.0.1/dist/objects/coffe_cup/gltf/scene.glb",
            ///     "blend_path":"D:\\Projets\\Holo\\www\\dist\\objects\\coffe_cup\\blender\\scene.blend"
            ///  },
            ///  {
            ///     "name":"coffee-cup-with-plate",
            ///     "gltf_url":"http://127.0.0.1/dist/objects/coffee-cup-with-plate/gltf/scene.gltf",
            ///     "blend_path":"D:\\Projets\\Holo\\www\\dist\\objects\\coffee-cup-with-plate\\blender\\scene.blend"
            ///  }]
            result = new Array(data.length + 1);
            result[0] = this.no_object_name;

            for(let index in data)
            {
                let obj = data[index];
                let name = obj.name.toString();

                // index + 1 car l’indice 0 est celui pour définir le choix: pas
                // d’objet.
                result[parseInt(index) + 1] = name;

                this.objects[name] = new Object(name, obj.gltf_url.toString());
            }
        });

        request.fail((jqXHR, textStatus, errorThrown) =>
        {
            alert("Request failed: " + textStatus + ' ' + errorThrown + ' ' + jqXHR.responseText);
        });

        return result;
    }

    has_selected_object()
    {
        return this.selected_object_name in this.objects; //!(this.selected_object_name == this.no_object_name);
    }

    /**
     * Renovie le nom plus l’extension d’un chemin de fichier.
     * @param {String} path Chemin du fichier.
     * @returns le nom plus l’extension du chemin du fichier.
     */
    basename(path)
    {
        return path.split(/[\\/]/).pop();
    }

    async update_selected_object_name()
    {
        if(!(this.gltf_scene === null))
            this.group.remove(this.gltf_scene);

        this.gltf_scene = null;

        if(this.has_selected_object())
        {
            //let folder_path = "./objects/" + this.selected_object_name + "/gltf/";
            const obj = this.objects[this.selected_object_name];

            //* 
            // https://threejsfundamentals.org/threejs/lessons/threejs-load-gltf.html
            // https://stackoverflow.com/questions/60704912/play-a-gltf-animation-three-js
            const folder_path = obj.gltf_url;

            const file_basename = this.basename(folder_path);
            const folder_path_str = String(folder_path);
            const directory_path = folder_path_str.substring(0, folder_path_str.length - String(file_basename).length);

            console.log("file_basename: " + file_basename);
            console.log("folder_path: " + folder_path);
            console.log("directory_path: " + directory_path);

            const gltf_loader = new GLTFLoader().setPath(directory_path);

            const [gltf] = await Promise.all([
                gltf_loader.loadAsync(file_basename),
            ]);

            this.gltf_scene = gltf.scene;
            this.group.add(this.gltf_scene);

            this.mixer = new THREE.AnimationMixer(this.gltf_scene);

            //console.log(gltf.animations.length);

            gltf.animations.forEach((clip) =>
            {
                this.mixer.clipAction(clip).play();
            });
            /*/
            const gltf_loader = new GLTFLoader();
            gltf_loader.load(obj.gltf_url, (gltf) =>
            {
                this.gltf_scene = gltf.scene;
                this.group.add(this.gltf_scene);
            });

            //*/

            /*
            new GLTFLoader()
                .setPath('models/gltf/')
                .setDRACOLoader(new DRACOLoader().setDecoderPath('js/libs/draco/gltf/'))
                .load('IridescentDishWithOlives.glb', (gltf) =>
                {

                    mixer = new THREE.AnimationMixer(gltf.scene);
                    mixer.clipAction(gltf.animations[0]).play();
                    this.scene.add(gltf.scene);

                });
            */
            //mesh = new THREE.Mesh(geometry, material);
            //group_mesh.add(mesh);
        }
    }

    /** Fonction appelée lors du rendu de chaque frame.
     * @param {Float} time temps depuis le lancement de l’application en 
     * milli-secondes.
     */
    render(time)
    {
        if(this.mixer != null)
        {
            var delta = this.clock.getDelta()
            this.mixer.update(delta);
        }
    }
}

export { Objects };
