import * as THREE from 'three';

//Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
import { stringify } from 'uuid';

class LightSet
{
    /**
     * Constructeur de la classe LightSet
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     * @param {String} name Le nom de ce système/ensemble de lumières.
     */
    constructor(scene, gui, name)
    {
        if(this.constructor == LightSet)
        {
            throw new Error("LightSet est une classe abstraite qui ne peut " +
                "être instanciée.");
        }
        else
        {
            this.scene = scene;
            this.gui = gui;
            this.name = name;

            /** Permet de savoir si cet ensemble de lumières à été instancié. 
             * @type {boolean}
            */
            this.setup_done = false;

            /** Le dossier/nœud de l’interface graphique contenant les 
             * composants manipulant cet ensenble de lumières. 
             * @type {GUI}
            */
            this.folder = null;
        }
    }

    setup()
    {
        if(this.setup_done)
        {
            throw new Error("Cet ensemble de lumière a déjà été instancié.");
        }
        else
        {
            this.setup_done = true;
            this.folder = this.gui.addFolder(this.name);

            this.setup_scene();
            this.setup_gui();
        }
    }

    clear()
    {
        if(!this.setup_done)
        {
            throw new Error("Cet ensemble de lumière n’a pas été instancié.");
        }
        else
        {
            this.clear_scene();
            this.clear_gui();

            this.gui.removeFolder(this.folder);
            this.folder = null;
            this.setup_done = false;
        }
    }

    setup_scene()
    {
        throw new Error("La méthode 'setup_scene()' doit être implémentée.");
    }

    setup_gui()
    {
        throw new Error("La méthode 'setup_gui()' doit être implémentée.");
    }

    clear_scene()
    {
        throw new Error("La méthode 'clear_scene()' doit être implémentée.");
    }

    /** Peut ne pas être appelé puisque le nœud parent « this.folder » sera 
     * supprimé. 
     */
    clear_gui()
    { }
}

class AmbiantLightSet extends LightSet
{
    /**
     * Constructeur de la classe AmbiantLightSet
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     */
    constructor(scene, gui)
    {
        super(scene, gui, "Ambiante");

        this.light = null;
    }

    setup_scene()
    {
        this.light = new THREE.AmbientLight(0xFFFFFF, 1);
        this.scene.add(this.light);
    }

    setup_gui()
    {
        this.folder.add(this.light, 'intensity', 0, 1, 0.01).name("Intensité");
        //this.folder.addColor(this.light, 'color').name("Couleur");
    }

    clear_scene()
    {
        this.scene.remove(this.light);
        this.light = null;
    }
}

class SunSet extends LightSet
{
    /**
     * Constructeur de la classe SunSet
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     */
    constructor(scene, gui)
    {
        super(scene, gui, "Soleil");

        /** La lumière directionnelle 
        *  @type {THREE.DirectionalLight}
        */
        this.light = null;

        /** La cible de la lumière directionnelle
         *  @type {THREE.Object3D}
         */
        this.target = null;
    }

    setup_scene()
    {
        this.target = new THREE.Object3D();
        this.target.position.set(3, -4, 5);
        this.scene.add(this.target);

        this.light = new THREE.DirectionalLight(0xffffff, 0.125);
        this.light.position.set(-16.7141, 23.6383, 22.793);
        this.light.target = this.target;
        this.light.intensity = 1.7;
        this.scene.add(this.light);

        //const light = new THREE.DirectionalLightHelper(light);
        //scene.add(light);

        //const pointLight = new THREE.PointLight(0xffffff, 1.5);
        //pointLight.position.set(0, 100, 90);
        //scene.add(pointLight);   
    }

    setup_gui()
    {
        this.folder.add(this.light, 'intensity', 0, 2, 0.01).name("Intensité");
        //this.folder.addColor(this.light, 'color').name("Couleur");
    }

    clear_scene()
    {
        this.scene.remove(this.light);
        this.light = null;
    }
}

class ThreeSunSet extends LightSet
{
    /**
     * Constructeur de la classe ThreeSunSet
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     */
    constructor(scene, gui)
    {
        super(scene, gui, "Trois soleils");

        /** La lumière directionnelle numéro une.
        *  @type {THREE.DirectionalLight}
        */
        this.light1 = null;

        /** La lumière directionnelle numéro deux.
        *  @type {THREE.DirectionalLight}
        */
        this.light2 = null;

        /** La lumière directionnelle numéro trois.
        *  @type {THREE.DirectionalLight}
        */
        this.light3 = null;
    }

    setup_scene()
    {
        this.light1 = new THREE.DirectionalLight(0xffffff, 1.7);
        this.light1.rotation.set(90, 0, 0);
        this.scene.add(this.light1);
        
        this.light2 = new THREE.DirectionalLight(0xffffff, 1.7);
        this.light2.rotation.set(30, 0, 0);
        this.scene.add(this.light2);

        this.light3 = new THREE.DirectionalLight(0xffffff, 1.7);
        this.light3.rotation.set(-90, 0, 0);
        this.scene.add(this.light3);

        this.scene.add(new THREE.DirectionalLightHelper(this.light1));
        this.scene.add(new THREE.DirectionalLightHelper(this.light2));
        this.scene.add(new THREE.DirectionalLightHelper(this.light3));
    }

    setup_gui()
    {
        this.folder.add(this.light1, 'intensity', 0, 2, 0.01).name("Intensité 1");
        this.folder.add(this.light2, 'intensity', 0, 2, 0.01).name("Intensité 2");
        this.folder.add(this.light3, 'intensity', 0, 2, 0.01).name("Intensité 3");
    }

    clear_scene()
    {
        this.scene.remove(this.light1);
        this.scene.remove(this.light2);
        this.scene.remove(this.light3);
        this.light1 = null;
        this.light2 = null;
        this.light3 = null;
    }
}

class SunSet2 extends LightSet
{
    /**
     * Constructeur de la classe SunSet
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     */
    constructor(scene, gui)
    {
        super(scene, gui, "Soleil 2");

        /** La lumière directionnelle 
        *  @type {THREE.DirectionalLight}
        */
        this.light = null;

        /** La cible de la lumière directionnelle
         *  @type {THREE.Object3D}
         */
        this.target = null;

        /** La lumière directionnelle 
        *  @type {THREE.DirectionalLight}
        */
        this.ambiant_light = null;
    }

    setup_scene()
    {
        this.target = new THREE.Object3D();
        this.target.position.set(3, -4, 5);
        this.scene.add(this.target);

        this.light = new THREE.DirectionalLight(0xffffff, 0.125);
        this.light.position.set(-16.7141, 23.6383, 22.793);
        this.light.target = this.target;
        this.light.intensity = 1.2;
        this.scene.add(this.light);

        this.ambiant_light = new THREE.AmbientLight(0xFFFFFF, 0.3);  
        this.scene.add(this.ambiant_light);
    }

    setup_gui()
    {
        this.folder.add(this.light, 'intensity', 0, 2, 0.01).name("Intensité");
        this.folder.add(this.ambiant_light, 'intensity', 0, 1, 0.01).name("Ambiant");
        //this.folder.addColor(this.light, 'color').name("Couleur");
    }

    clear_scene()
    {
        this.scene.remove(this.light);
        this.light = null;
        this.ambiant_light = null;
    }
}

/**
 * @class La classe qui va créer et gérer les lumières de la scène.
 */
class Lights 
{
    /**
     * Constructeur de la classe Lights
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     */
    constructor(scene, gui)
    {
        this.scene = scene;
        this.gui = gui;

        /** Le nœud de l’interafce contenant les paramètres des lumières 
         * @type {GUI}
         */
        this.folder = null;

        /** Tableau de « LightSet »
         * @type {Map<String, LightSet>}
         */
        this.light_sets = new Map();

        /** Le nom du système de lumière sélectionné.
         * @type {String}
         */
        this.light_set_name = "";

        /** Variable pour stacker les noms des système de lumières.
         * @type {Array<String>}
         */
        this.gui_light_set_names = new Array();

        this.setup_scene();

        // Ne pas le désactiver c’est lui qui instancie la lumière… 
        // @todo la gui devrait être masquée.
        this.setup_gui();
    }

    /** Création des lumières */
    setup_scene()
    { }

    /** Raccourcis pour ajouter un ensemble de lumières.
     * @type {LightSet} light_set L’ensemble de lumière à ajouter.
     */
    add_light_set(light_set)
    {
        this.light_sets.set(light_set.name, light_set);
    }

    /** Création de l'interface permettant de manipuler les lumières */
    setup_gui()
    {
        this.folder = this.gui.addFolder('Lumière');
        
        var light_setup = new SunSet2(this.scene, this.folder);
        this.light_set_name = light_setup.name;
        light_setup.setup();

        this.add_light_set(light_setup);
        this.add_light_set(new SunSet(this.scene, this.folder));
        this.add_light_set(new AmbiantLightSet(this.scene, this.folder));
        this.add_light_set(new ThreeSunSet(this.scene, this.folder));

        this.gui_light_set_names = new Array(this.light_sets.size);

        var index = 0;
        for(const [key, value] of this.light_sets) 
        {
            this.gui_light_set_names[index] = key;
            index++;
        }

        this.folder.add(this, "light_set_name", this.gui_light_set_names).name('Système d’éclairage').onChange((name) =>
        {
            for(const [key, value] of this.light_sets) 
                if(value.setup_done)
                    value.clear();

            this.light_sets.get(name).setup();
        });
    }
}

export { Lights };
export { LightSet };
