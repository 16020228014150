import $ from 'jquery';

import * as THREE from 'three';

// Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

// https://typedrawers.com/discussion/3812/ttf-to-json-conversion
// https://github.com/mrdoob/three.js/blob/master/examples/webgl_loader_ttf.html
// Note : C:\Windows\Fonts\ contient des ttf
// il faut inclure opentype « npm install opentype.js »
//import { opentype } from 'three/examples/jsm/libs/opentype.module.min.js';
//import { TTFLoader } from 'three/examples/jsm/loaders/TTFLoader.js';
import { TTFLoader } from './ttfloader';

// r133: THREE.FontLoader has been moved to /examples/jsm/loaders/FontLoader.js
import * as ThreeFont from 'three/examples/jsm/loaders/FontLoader.js'


// r133: THREE.TextGeometry has been moved to /examples/jsm/geometries/TextGeometry.js
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

import { TextAreaController } from './text_area_controller';

var assert = require('assert');

/**
* @class Défini les paramètre d’une police de caractère pour être chargé dans
* ThreeJs.
*/
class Font
{
    constructor(name, extension, url)
    {
        /**
         * Le nom de la police de caractères.
         * @type {String}
         */
        this.name = name;

        /**
        * L'extension de la police de caractère.
        * @type {String}
        */
        this.extension = extension;

        /**
         * L’adresse internet de la police de caractères à charger dans Threejs
         * @type {String}
         */
        this.url = url;
    }
}


/**
 * @class La classe qui va créer et gérer le texte de la scène.
 */
class Text 
{
    /**
     * Constructeur de la classe Text
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     */
    constructor(scene, gui)
    {
        this.scene = scene;
        this.gui = gui;

        /** Le nœud contenant le texte.
         * @type {THREE.Group}
         */
        this.group = null;

        /** La géométrie du texte
         * @type {TextGeometry}
         */
        this.geo = null;

        /** Le maillage du texte.
         * @type {THREE.Mesh}
         */
        this.mesh = null;

        /** La liste des matériaux de texte.
         * @type {Array<THREE.Material>}
         */
        this.materials = null;

        /** La police utilsée. Cette variable est affectée pas la fonction
         * membre : load_json_font et load_ttf_font.
         * @type {THREE.Font}
         */
        this.font = undefined;

        /** Liste des policies de caractères disponibles.
         * La liste est définie pas la fonction get_ttf_fonts_names()
         * @type {Array<Font>}
         */
        this.fonts = [];

        /** Les défférentes polices contenu dans le dossier:
         * ./fonts.
         * @type {Arry<string>}
         * @todo les récupérer automatiquement.
        */
        this.font_map = this.get_ttf_fonts_names(); //["helvetiker", "optimer", "gentilis", "droid/droid_sans", "droid/droid_serif"];

        /** Le nom de la police sélectionnée par l’utilisateur.
         * @type {String}
        */
        this.font_name = this.font_map[0];

        /** Le poid sélectionnée pour la policie.
         * @type {String}
         * @see Text.weight_map
         */
        this.font_weight = "bold";

        /** Énumération des poids disponible pour la police
         * @type {String}
         * @todo Faut-il le définir en fonction de la policie sélectionnée ? 
         * @todo Faudrait-il l’utiliser?
         */
        this.weight_map =
        {
            "regular": 0,
            "bold": 1
        };

        this.reverse_weight_map = [];
        for(const i in this.weight_map)
            this.reverse_weight_map[this.weight_map[i]] = i;

        /** La taille de la police. 
         * @type {Int}
        */
        this.font_size = 70;

        /** épaisseur de l’extrusion de la police
         * @type {Int}
        */
        this.font_height = 20;

        /** Élévation du texte. 
         * @type {Float}
        */
        this.hover = 0.0;

        /** Le message que désire afficher l’utilisateur.
         * @type {String}
         */
        this.message = 'holo';

        /** La gui pour éditer le message
         * @type {GUI}
         */
        this.message_gui = null;

        /** L’épaisseur du texte
         * @type {Float}
         */
        this.thickness = 30.0;

        /** L’épaisseur du chanfrein de texte. 
         * @type {Float}
        */
        this.bevel_thickness = 0.1;

        /** Taille du chonfrein.
         * @type {Float}
         */
        this.bevel_size = 0.0;

        /** Active ou non le chanfrein du texte.
         * @type {Boolean}
         */
        this.bevel_enabled = true;

        /** Nombre de points de la courbe représentant le texte. 
         * @type {Int}
        */
        this.curve_segments = 15;

        /** Énumération des animations pré-établie pour le texte.
         * @type {Array<string>}
         * @see Text.animation
         * @see Text.animation_choice
         */
        this.animation_map = ["Aucune", "Tic Tac"];

        /** L’animation du texte sélectionnée.
         * @type {String}
         * @see Text.animation_map
         * @see Text.animation_choice
         * @see Text.animation
         */
        this.animation_choice = this.animation_map[0];

        /** Le nœud de l’interafce contenant les paramètres de la caméra 
         * @type {GUI}
        */
        this.folder = null;

        this.setup_scene();
        this.setup_gui();
    }

    //const material = new THREE.MeshStandardMaterial( {
    //    color: 0xffffff,
    //    metalness: 0,
    //    roughness: 0,
    //    envMap: cubeTexture,
    //    envMapIntensity: API.envMapIntensity,
    //} );


    /** Création du texte */
    setup_scene()
    {

        /* this.materials =
             [
                 new THREE.MeshPhongMaterial({ color: 0xffffff, flatShading: true }), // front
                 new THREE.MeshPhongMaterial({ color: 0xffffff }) // side
             ];
             */
        this.material = new THREE.MeshPhongMaterial({ color: 0xffffff });

        this.data = {
            color: this.material.color.getHex(),
            emissive: this.material.emissive.getHex(),
            specular: this.material.specular.getHex()
        };

        this.group = new THREE.Group();
        this.group.position.y = 0;
        this.scene.add(this.group);

        this.load_ttf_font();

        this.create_geometrie();
    }

    /** Création de l'interface permettant de manipuler le texte. */
    setup_gui()
    {
        this.folder = this.gui.addFolder('Texte');

        this.message_gui = TextAreaController.add(this.folder, this, 'message').name('Texte').onChange((value) =>
        {
            this.message = value;
            this.refresh();
        });

        this.folder.add(this, 'font_name', this.font_map).name('Police').onChange((value) =>
        {
            this.load_ttf_font();
        });

        this.folder.addColor(this.data, 'color').name("Couleur").onChange(() => 
        {
            this.material.color.setHex(Number(this.data.color.toString().replace('#', '0x')));
        });

        this.folder.add(this.group.scale, 'x', 0.1, 2).name('Échelle').onChange(() =>
        {
            this.group.scale.z = this.group.scale.y = this.group.scale.x;
        });

        this.folder.add(this, 'thickness', 0, 400).name('Épaisseur').onChange(() =>
        {
            this.refresh();
        });

        this.folder.add(this, 'hover', -250, 250).name('Hauteur').onChange(() =>
        {
            this.refresh();
        });

        this.folder.add(this, 'animation_choice', this.animation_map).name("Animation").onChange((value) => { });

        this.folder.open();
    }

    create_geometrie()
    {
        // Peut être appelé avant la fin du chargement d’une fonte. Le test ne 
        // lève donc pas d’exception.
        if(!(this.font === undefined) && this.font != null)
        {
            //console.log('this.font: {type: ' + typeof  this.font + '}, {value: ' + JSON.stringify(this.font) + '}');
            // text — The text that needs to be shown.
            // parameters — Object that can contains the following parameters.
            //
            //  font — an instance of THREE.Font.
            //  size — Float. Size of the text. Default is 100.
            //  height — Float. Thickness to extrude text. Default is 50.
            //  curveSegments — Integer. Number of points on the curves. Default is 12.
            //  bevelEnabled — Boolean. Turn on bevel. Default is False.
            //  bevelThickness — Float. How deep into text bevel goes. Default is 10.
            //  bevelSize — Float. How far from text outline is bevel. Default is 8.
            //  bevelOffset — Float. How far from text outline bevel starts. Default is 0.
            //  bevelSegments — Integer. Number of bevel segments. Default is 3.
            this.geo = new TextGeometry(this.message,
                {
                    font: this.font,
                    size: this.font_size,
                    height: this.thickness,
                    curveSegments: this.curve_segments,
                    bevelThickness: this.bevel_thickness,
                    bevelSize: this.bevel_size,
                    bevelEnabled: this.bevel_enabled
                });

            this.geo.computeBoundingBox();

            const center_offset = - 0.5 * (this.geo.boundingBox.max.x - this.geo.boundingBox.min.x);

            //this.mesh = new THREE.Mesh(this.geo, this.materials);
            this.mesh = new THREE.Mesh(this.geo, this.material);
            // console.log(this.mesh);

            this.mesh.position.x = center_offset;
            this.mesh.position.y = this.hover;
            this.mesh.position.z = 0;

            this.mesh.rotation.x = 0;
            this.mesh.rotation.y = Math.PI * 2;

            this.group.add(this.mesh);
        }
    }

    /** Détruit le texte pour le reconstruire.*/
    refresh()
    {
        this.group.remove(this.mesh);

        if(!this.message)
            return;

        this.create_geometrie();
    }

    /** Retourne la liste des noms des police de caractères
     * @type {Array<String>}
     */
    get_ttf_fonts_names()
    {
        let result = [this.no_object_name];

        let request = $.ajax({
            type: 'POST',
            url: "fonts.php",
            dataType: "json",
            async: false
        });

        request.done((data) =>
        {
            /// [{  
            ///     "name":"arial",
            ///     "extension": "ttf",
            ///     "url":"http://127.0.0.1/dist/fonts/ttf/arial.ttf",
            ///     "path":"D:\\Projets\\Holo\\www\\dist\\fonts\\ttf\\arial.ttf"
            ///  },
            ///  {
            ///     "name":"arial",
            ///     "extension": "ttf",
            ///     "url":"http://127.0.0.1/dist/fonts/ttf/georgia.ttf",
            ///     "path":"D:\\Projets\\Holo\\www\\dist\\fonts\\ttf\\georgia.ttf"
            ///  }]
            result = new Array(data.length);

            for(let index in data)
            {
                let font = data[index];
                let name = font.name.toString();

                result[parseInt(index)] = name;

                this.fonts[name] = new Font(name, font.extension.toString(), font.url.toString());
            }
        });

        request.fail((jqXHR, textStatus, errorThrown) =>
        {
            alert("Request failed: " + textStatus + ' ' + errorThrown + ' ' + jqXHR.responseText);
        });

        return result;
    }

    /**Doit être appelé en premier lieu pour que les police de
     *caractères soient téléchargées avant la création de la géométrie
     * du texte.
    */
    load_json_font()
    {
        const url = window.location.href + 'fonts/' + this.font_name + '_' + this.font_weight + '.typeface.json';

        const loader = new ThreeFont.FontLoader();
        loader.load(url, (response) =>
        {
            this.font = response;
            this.refresh();
        });
    }

    load_ttf_font()
    {
        const url = this.fonts[this.font_name].url;

        //console.log(url);

        const loader = new TTFLoader();
        loader.load(url,
            // onLoad
            (json) =>
            {
                // pour éviter un bogue où la référence disparait lors de la
                // destruction du TextGeometry.
                this.json = json; 
                this.font = new ThreeFont.Font(json);
                //console.log(json);
                this.refresh();
            },
            // onProgress
            (xhr) =>
            {
                //console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            },
            // onError
            (err) =>
            {
                console.log('Erreur de chargement de la police (' + this.font_name + '): ' + err);
            });        
    }

    /** Animation du texte. 
     * @param {Float} time_s Temps écoulé en seconde depuis le lancement de 
     * l’application.
     * @param {Float} video_lenght Durée de la vidéo en seconde.
     */
    animation(time_s, video_lenght)
    {
        // Lors de l’export ça fait déconner le placement du texte.
        //this.group.rotation.x = time / 2000;

        // Aucune
        if(this.animation_choice == this.animation_map[0]) 
        {
            this.group.rotation.y = 0;
        }
        // Droite Gauche
        else if(this.animation_choice == this.animation_map[1]) 
        {
            // Toute les «video_lenght» secondes la rotation reviens à 
            // zéro. 
            this.group.rotation.y = Math.sin(time_s * 2.0 * Math.PI / video_lenght);
        }
    }
}

export { Text };
