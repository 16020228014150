import * as THREE from 'three';

//Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

/**
 * @class La classe qui va créer et gérer la caméra de la scène.
 */
class Cameras 
{
    /**
     * Constructeur de la classe Camera
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     * @param {THREE.WebGLRenderer} renderer
     * @param {Int} width 
     * @param {Int} height 
     */
    constructor(scene, gui, renderer, width, height)
    {
        this.scene = scene;
        this.gui = gui;
        this.renderer = renderer;
        this.width = width;
        this.height = height;


        /** Caméra de la scène
         *  @type {THREE.PerspectiveCamera}
         */
        this.camera = null;

        /** Le nœud de l’interafce contenant les paramètres de la caméra 
         * @type {GUI}
        */
        this.folder = null;

        this.focal_length = 0;

        this.setup_scene();
        //this.setup_gui();
    }

    /** Création de la caméra */
    setup_scene()
    {
        this.camera = new THREE.PerspectiveCamera(70, this.width / this.height, 0.1, 1500.0);
        this.camera.position.set(0, 0, 400);
    
        const camera_helper = new THREE.CameraHelper(this.camera);
        this.scene.add(camera_helper);

        this.focal_length = this.camera.getFocalLength();

        // Pour correspondre avec blender. La matrice de la caméra de Threejs
        // est bizarre.
        //this.camera.projectionMatrix.elements[0] = 1; // au lieu de 1.4
        //this.camera.projectionMatrix.elements[5] = 1; // au lieu de 1.4
        //this.camera.zoom = 0.8025; 
        this.camera.updateProjectionMatrix();


    }

    /** Création de l'interface permettant de manipuler la caméra. */
    setup_gui()
    {
        this.folder = this.gui.addFolder('Caméra');

        var pos = this.camera.position;
        this.folder.add(pos, 'y', pos.y - 20, pos.y + 20);
        this.folder.add(pos, 'z', pos.z - 100, pos.z + 100);

        this.folder.add(this.camera.scale, 'x', 0, 1, 0.01);
        this.folder.add(this.camera.scale, 'y', 0, 1, 0.01);
        this.folder.add(this.camera.scale, 'z', 0, 1, 0.01);

        this.folder.add(this.camera, 'fov', 0, 100, 1).onChange((value) =>
        {
            this.camera.updateProjectionMatrix();
        });

        this.folder.add(this.camera, 'aspect', 0, 4, 0.01).onChange((value) =>
        {
            this.camera.updateProjectionMatrix();
        }); 

        this.folder.add(this, 'focal_length', 0, 100, 0.1).onChange((value) =>
        {
            this.camera.setFocalLength(this.focal_length);
            this.camera.updateProjectionMatrix();
        }); 
        
        this.folder.add(this.camera, 'zoom').onChange((value) => 
        {
            this.camera.updateProjectionMatrix();
        });

        this.folder.add(this.camera, 'filmGauge').onChange((value) => 
        {
            this.camera.updateProjectionMatrix();
        }); 

        this.folder.add(this.camera, 'filmOffset').onChange((value) => 
        {
            this.camera.updateProjectionMatrix();
        });
        
        var matrix_folder = this.folder.addFolder('Matrice');
        var matrix = this.camera.projectionMatrix.elements;

        for(var i = 0; i < 16; i++)
        {
            matrix_folder.add(this.camera.projectionMatrix.elements, i);
        }
    }
    
    on_window_resize(width, height) 
    {
        this.width = width;
        this.height = height;

        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        //this.camera.projectionMatrix.elements[0] = 1;
        //this.camera.projectionMatrix.elements[5] = 1;
        
    }

    /** Fonction appelée lors du rendu de chaque frame.
     * @param {Float} time temps depuis le lancement de l’application en 
     * milli-secondes.
     */
    render(time)
    {}
}

export { Cameras };
