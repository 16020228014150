// Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI, controllers, dom } from 'three/examples/jsm/libs/dat.gui.module';

//(parameter) _Controller: typeof GUI.Controller;
//_Controller = typeof GUI.Controller;
var ARR_EACH = Array.prototype.forEach;
var ARR_SLICE = Array.prototype.slice;
var Common = {
	BREAK: {},
	extend: function extend( target ) {

		this.each( ARR_SLICE.call( arguments, 1 ), function ( obj ) {

			var keys = this.isObject( obj ) ? Object.keys( obj ) : [];
			keys.forEach( function ( key ) {

				if ( ! this.isUndefined( obj[ key ] ) ) {

					target[ key ] = obj[ key ];

				}

			}.bind( this ) );

		}, this );
		return target;

	},
	defaults: function defaults( target ) {

		this.each( ARR_SLICE.call( arguments, 1 ), function ( obj ) {

			var keys = this.isObject( obj ) ? Object.keys( obj ) : [];
			keys.forEach( function ( key ) {

				if ( this.isUndefined( target[ key ] ) ) {

					target[ key ] = obj[ key ];

				}

			}.bind( this ) );

		}, this );
		return target;

	},
	compose: function compose() {

		var toCall = ARR_SLICE.call( arguments );
		return function () {

			var args = ARR_SLICE.call( arguments );
			for ( var i = toCall.length - 1; i >= 0; i -- ) {

				args = [ toCall[ i ].apply( this, args ) ];

			}
			return args[ 0 ];

		};

	},
	each: function each( obj, itr, scope ) {

		if ( ! obj ) {

			return;

		}
		if ( ARR_EACH && obj.forEach && obj.forEach === ARR_EACH ) {

			obj.forEach( itr, scope );

		} else if ( obj.length === obj.length + 0 ) {

			var key = void 0;
			var l = void 0;
			for ( key = 0, l = obj.length; key < l; key ++ ) {

				if ( key in obj && itr.call( scope, obj[ key ], key ) === this.BREAK ) {

					return;

				}

			}

		} else {

			for ( var _key in obj ) {

				if ( itr.call( scope, obj[ _key ], _key ) === this.BREAK ) {

					return;

				}

			}

		}

	},
	defer: function defer( fnc ) {

		setTimeout( fnc, 0 );

	},
	debounce: function debounce( func, threshold, callImmediately ) {

		var timeout = void 0;
		return function () {

			var obj = this;
			var args = arguments;
			function delayed() {

				timeout = null;
				if ( ! callImmediately ) func.apply( obj, args );

			}
			var callNow = callImmediately || ! timeout;
			clearTimeout( timeout );
			timeout = setTimeout( delayed, threshold );
			if ( callNow ) {

				func.apply( obj, args );

			}

		};

	},
	toArray: function toArray( obj ) {

		if ( obj.toArray ) return obj.toArray();
		return ARR_SLICE.call( obj );

	},
	isUndefined: function isUndefined( obj ) {

		return obj === undefined;

	},
	isNull: function isNull( obj ) {

		return obj === null;

	},
	isNaN: function ( _isNaN ) {

		function isNaN() {

			return _isNaN.apply( this, arguments );

		}
		isNaN.toString = function () {

			return _isNaN.toString();

		};
		return isNaN;

	}( function ( obj ) {

		return isNaN( obj );

	} ),
	isArray: Array.isArray || function ( obj ) {

		return obj.constructor === Array;

	},
	isObject: function isObject( obj ) {

		return obj === Object( obj );

	},
	isNumber: function isNumber( obj ) {

		return obj === obj + 0;

	},
	isString: function isString( obj ) {

		return obj === obj + '';

	},
	isBoolean: function isBoolean( obj ) {

		return obj === false || obj === true;

	},
	isFunction: function isFunction( obj ) {

		return obj instanceof Function;

	}
};

/**
 * @class Provides a textarea input to alter the string property of an object.
 *
 * @extends dat.controllers.Controller
 *
 * @param {Object} object The object to be manipulated
 * @param {string} property The name of the property to be manipulated
 */
class TextAreaController extends controllers.Controller// Controller
{
    constructor(object, property)
    {
        super(object, property);

        const _this = this;

        function onChange()
        {
            _this.setValue(_this.__input.value);
        }

        function onBlur()
        {
            if(_this.__onFinishChange)
            {
                _this.__onFinishChange.call(_this, _this.getValue());
            }
        }

        this.__input = document.createElement('textarea');
        this.__input.setAttribute('type', 'textarea');
        this.__input.style.width = "inherit";
        this.__input.style.boxSizing = "border-box";

        dom.dom.bind(this.__input, 'keyup', onChange);
        dom.dom.bind(this.__input, 'change', onChange);
        dom.dom.bind(this.__input, 'blur', onBlur);
        dom.dom.bind(this.__input, 'keydown', function (e)
        {
            if(e.keyCode === 13)
            {   
                // Nous souhaitons pouvoir passer à la ligne à l’aide de la touche 
                // entrée. Donc nous mettons en commentaire this.blur();
                // quitte le focus du composent.
                //this.blur();
            }
        });

        this.updateDisplay();

        this.domElement.appendChild(this.__input);
    }

    updateDisplay()
    {
        // Stops the caret from moving on account of:
        // keyup -> setValue -> updateDisplay
        if(!dom.dom.isActive(this.__input))
        {
            this.__input.value = this.getValue();
        }
        return super.updateDisplay();
    }

    /**
     * Permet d’ajouter le contrôleur TextAera à un dossier.
     * @param {GUI} folder 
     * @param {*} object 
     * @param {*} property 
     * @returns 
     */
    static add(folder, object, property)
    {
        var params =
        {
            factoryArgs: Array.prototype.slice.call(arguments, 2)
        };

        if(object[property] === undefined)
        {
            throw new Error(`Object "${object}" has no property "${property}"`);
        }

        let controller = new TextAreaController(object, property);

        if(params.before instanceof controllers.Controller)
        {
            params.before = params.before.__li;
        }

        recallSavedValue(folder, controller);

        dom.dom.addClass(controller.domElement, 'c');
        controller.domElement.style.display = "contents";

        const name = document.createElement('span');
        dom.dom.addClass(name, 'property-name');
        name.innerHTML = controller.property;

        const container = document.createElement('div');
        container.appendChild(name);
        container.appendChild(controller.domElement);

        const li = addRow(folder, container, params.before);

        var cr_elem = dom.dom.addClass(li, GUI.CLASS_CONTROLLER_ROW);
        // class="cr string" ce parent doit ajuster sa hauteur automatiquement 
        //au contenu de textarea.
        li.style.height = "auto";

        dom.dom.addClass(li, typeof controller.getValue());

        augmentController(folder, li, controller);

                
        // class="cr string" ce parent doit ajuster sa hauteur automatiquement 
        //au contenu de textarea.
        //controller.domElement.parentElement.parentElement.parentElement.style.height = "auto";

        folder.__controllers.push(controller);

        return controller;
    }


}

function recallSavedValue(gui, controller) 
{
    var root = gui.getRoot();
    var matchedIndex = root.__rememberedObjects.indexOf(controller.object);
    if(matchedIndex !== - 1)
    {

        var controllerMap = root.__rememberedObjectIndecesToControllers[matchedIndex];
        if(controllerMap === undefined)
        {

            controllerMap = {};
            root.__rememberedObjectIndecesToControllers[matchedIndex] = controllerMap;

        }
        controllerMap[controller.property] = controller;
        if(root.load && root.load.remembered)
        {

            var presetMap = root.load.remembered;
            var preset = void 0;
            if(presetMap[gui.preset])
            {

                preset = presetMap[gui.preset];

            } else if(presetMap[DEFAULT_DEFAULT_PRESET_NAME])
            {

                preset = presetMap[DEFAULT_DEFAULT_PRESET_NAME];

            } else
            {

                return;

            }
            if(preset[matchedIndex] && preset[matchedIndex][controller.property] !== undefined)
            {

                var value = preset[matchedIndex][controller.property];
                controller.initialValue = value;
                controller.setValue(value);

            }

        }

    }
}

function augmentController(gui, li, controller)
{

    controller.__li = li;
    controller.__gui = gui;
    Common.extend(controller,
    {
        options: function options(_options)
        {

            if(arguments.length > 1)
            {

                var nextSibling = controller.__li.nextElementSibling;
                controller.remove();
                return _add(gui, controller.object, controller.property, {
                    before: nextSibling,
                    factoryArgs: [Common.toArray(arguments)]
                });

            }
            if(Common.isArray(_options) || Common.isObject(_options))
            {

                var _nextSibling = controller.__li.nextElementSibling;
                controller.remove();
                return _add(gui, controller.object, controller.property, {
                    before: _nextSibling,
                    factoryArgs: [_options]
                });

            }

        },
        name: function name(_name)
        {

            controller.__li.firstElementChild.firstElementChild.innerHTML = _name;
            return controller;

        },
        listen: function listen()
        {

            controller.__gui.listen(controller);
            return controller;

        },
        remove: function remove()
        {

            controller.__gui.remove(controller);
            return controller;

        }
    });

    controller.setValue = Common.compose(function (val)
    {

        if(gui.getRoot().__preset_select && controller.isModified())
        {

            markPresetModified(gui.getRoot(), true);

        }
        return val;

    }, controller.setValue);

}

function addRow(gui, newDom, liBefore) 
{
    var li = document.createElement('li');
    if(newDom)
    {
        li.appendChild(newDom);
    }

    if(liBefore) 
    {
        gui.__ul.insertBefore(li, liBefore);
    }
    else 
    {
        gui.__ul.appendChild(li);
    }

    gui.onResize();
    return li;
}

export { TextAreaController };