// Afficher les fonctions membres d’une instance:
// http://js.ipgirl.com/obtenir-des-fonctions-mthodes-dune-classe.html

import "./styles.css";

import * as THREE from 'three';
import { v4 as uuidv4 } from 'uuid';

// Pour faire des post traîtements.
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

//Documentation de l'API: https://github.com/dataarts/dat.gui/blob/master/API.md
//Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import Stats from 'three/examples/jsm/libs/stats.module.js';
//import ProgressBar from 'progressbar.js';

import { Lights } from './lights.js';
import { Cameras } from './cameras.js';
//import { Cameras } from './camera_cinemas.js';
import { Circle } from './circle.js';
import { FXAA } from './fxaa.js';
//import { PerspectiveFix } from '.perspective_fix.js';
import { Text } from './text.js';
import { Objects } from "./objects";
import { Particules } from "./particules";
import { Video } from "./video.js";
import { SSAO } from "./ssao.js";

class Editor
{
    /**
     * L’identifiant de la division html où sera incéré le rendu 3D.
     * @param {String} div_id 
     */
    constructor(div_id)
    {
        /** Permet aux fonctions de rendu de ne rien faire temps que cette 
         * variable est à faux. 
         * @type {Boolean}
         */
        this.construction_done = false;

        /** Identifiant de la session.
         * @type {String}
         * @remarks Pour débuter les tests on utilise un uuid à terme ça sera la
         * partie gérant les clients qui donnera l’identifiant de cette session.
         */
        this.session_id = uuidv4();

        /** C’est l’élément html où sera intégrer la fenêtre de rendu TreeJs. */
        this.container = document.getElementById(div_id);

        /** La description de la scène de ThreeJS 
         * @type {THREE.Scene}
        */
        this.scene = this.init_scene();

        /** La racine de l’interface graphique qui sera manipuler par 
         *  l’utilisateur. 
         * @type {GUI}
        */
        this.gui = new GUI();

        // Pour désactiver l’événement clavier « h » qui masque et affiche l’interface graphique
        // unbind(elem, event, func, newBool)
        //this.gui.dom.unbind(window, 'keydown', GUI._keydownHandler); // dom n’est pas défini
        if(window.removeEventListener) 
            window.removeEventListener('keydown', GUI._keydownHandler, false);
        else if(window.detachEvent) 
            window.detachEvent('onkeydown', GUI._keydownHandler);

        /** Le moteur de rendu de ThreeJS
         * @type {THREE.WebGLRenderer}
         */
        this.renderer = this.init_renderer();

        /** La largeur et la hauteur d’une vidéo que peuvent afficher les 
         * hélices.
         * @type {Int}
        */
        this.propeller_pixels_size = 480;

        /** Gestion des lumières
         * @type {Lights}
        */
        this.lights = new Lights(this.scene, this.gui);

        /** Gestion de la caméra
         * @type {Cameras}
        */
        this.cameras = new Cameras(this.scene,
            this.gui,
            this.renderer,
            this.get_width(),
            this.get_height());

        /** Raccourcis pour accéder à la caméra de l’éditeur.
         * @type {THREE.Camera}
         */
        this.camera = this.cameras.camera;

        /** Le compositeur du rendu.
         * @type {EffectComposer} 
         */
        this.composer = this.init_compositing();

        /** Shader d’ambiante occulision. 
         * @type {SSAO}
        */
        this.effect_ssao = new SSAO(this.scene,
            this.gui,
            this.camera,
            this.composer,
            this.renderer,
            this.get_width(),
            this.get_height());

        /** Le cercle symbolisant la surbface de rendu de l’hélice.
         * @type {Circle}
         */
        this.effect_circle = new Circle(this.scene,
            this.gui,
            this.composer,
            this.propeller_pixels_size,
            this.get_width(),
            this.get_height());

        /** Suite à l’activation des posts traîtements l’image se retrouve 
         * craînelées. Pour corriger cela nous utilisons un le post traîtement
         * FXAA.
         * @type {FXAA}
         */
        this.effect_fxaa = new FXAA(this.scene,
            this.gui,
            this.composer,
            this.renderer,
            this.get_width(),
            this.get_height());

        /*this.perspective_fix = new PerspectiveFix(this.scene, 
            this.gui,
            this.composer);
        */

        /** Le texte qui sera affiché et manipuler.
         * @type {Text}
         */
        this.text = new Text(this.scene, this.gui);

        /** Le gestionnaire qui affichera et manipulera l’objet sélectionné. 
         * @type {Objects} 
         */
        this.objects = new Objects(this.scene, this.gui);

        /** Le gestionnaire qui affichera la vidéo de l’effet de particule 
         * sélectionné. 
         * @type {Particules} 
         */
         this.particules = new Particules(this.scene, this.gui);

        /** Le gestionnarie qui permet de générer et télécharger la vidéo. */
        this.video = new Video(this.scene,
            this.gui,
            this.session_id,
            this.text,
            this.lights,
            this.objects,
            this.cameras,
            this.particules);

        /** L’interface pour afficher les performances du rendu 3D de ThreeJs.
         * @type {Stats}
         */
        this.stats = new Stats();
        this.container.appendChild(this.stats.dom);

        this.construction_done = true;
    }

    /** Pour finaliser l’éditeur appeler cette fonction après l’instanciation
     * de l’editeur.
     */
    go()
    {
        // Quelques aides visuelles
        // https://threejs.org/docs/#api/en/helpers/AxesHelper
        // x = rouge, y = vert, z = bleu
        //var axesHelper = new THREE.AxesHelper( 20 );
        //editor.scene.add( axesHelper );
        this.renderer.setAnimationLoop((time) =>
        {
            this.render(time);
        });

        window.addEventListener('resize', () =>
        {
            this.on_window_resize();
        });

        //gui.add(this, 'clear').name('Réinisialisation');
        this.camera.lookAt(this.text.group.position);
        // Pour initialiser le compositeur avec les bonnes dimensions des pixels.
        this.on_window_resize();
    }

    /** Créé et initialise les paramètres de bases de la scène. */
    init_scene()
    {
        var scene = new THREE.Scene();
        //scene.background = new THREE.Color(0x00000000);
        return scene;
    }

    /** Créé et initialise le rendu 3D. */
    init_renderer()
    {
        var renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(this.get_width(), this.get_height());
        this.container.appendChild(renderer.domElement);
        return renderer;
    }

    /** Créé et initialise le compositeur du rendu. */
    init_compositing()
    {
        var composer = new EffectComposer(this.renderer);
        composer.addPass(new RenderPass(this.scene, this.camera));
        return composer;
    }

    /**Retourne la largeur de la fenêtre de rendu. */
    get_width()
    {
        //return window.innerWidth;
        return this.propeller_pixels_size;
    }

    /** Retourne la largeur de la fenêtre de rendu. */
    get_height()
    {
        //return window.innerHeight;
        return this.propeller_pixels_size;
    }

    /** Fonction appelée lors du rendu de chaque frame.
     * @param {Float} time temps depuisle lancement de l’application en 
     * milli-secondes.
     */
    render(time) 
    {
        if(this.construction_done)
        {
            this.effect_circle.render(time);
            this.effect_fxaa.render(time);
            //this.perspective_fix.render(time);
            this.effect_ssao.render(time);
            this.video.render(time);
            this.objects.render(time);
            this.cameras.render(time);

            // Le temps en seconde
            var time_s = time / 1000.0;

            if(!this.video.rendering_progress)
            {
                this.renderer.clear();
                this.text.animation(time_s, this.video.video_lenght);
                //this.renderer.render(this.scene, this.camera);
                this.composer.render();
            }

            this.stats.update();
        }
    }

    /** Appelé à chaque redimentionnement de la fenêtre. */
    on_window_resize() 
    {
        if(this.construction_done)
        {
            const width = this.get_width();
            const height = this.get_height();

            this.cameras.on_window_resize(width, height);

            this.renderer.setSize(width, height);
            this.composer.setSize(width, height);

            this.effect_circle.on_window_resize(width, height);
            this.effect_fxaa.on_window_resize(width, height);
            this.effect_ssao.on_window_resize(width, height);
            //this.perspective_fix.on_window_resize(width, height);
        }
    }

    /** Permet de manipuler la caméra à l’aide de la sourie. C’est utile pour
     * voir par exemple où est placer un objet mal positionné.
     */
    test_set_orbit_camera()
    {
        var controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.enableZoom = false;
        controls.enablePan = false;
        controls.target.set(0, 0, - 0.2);
        controls.update();
    }
}

var editor = new Editor('three_container');
//editor.test_set_orbit_camera();
editor.go();
