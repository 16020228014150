import * as THREE from 'three';

// Pour faire des post traîtements.
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { SSAOPass } from 'three/examples/jsm/postprocessing/SSAOPass.js';

//Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

/**
 * @class Le post traîtement qui permet de faire de « l’ambiante occlusion ».
 * @see https://github.com/mrdoob/three.js/blob/master/examples/webgl_postprocessing_ssao.html
 */
class SSAO
{
    /**
     * Constructeur de la classe Circle
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {THREE.Camera} camera La camaré de la scène
     * @param {GUI} gui Interface graphique de l’éditeur.
     * @param {EffectComposer} composer Le compositeur du rendu.
     * @param {THREE.WebGLRenderer} renderer
     * @param {Int} width 
     * @param {Int} height
     */
    constructor(scene, gui, camera, composer, renderer, width, height)
    {
        this.scene = scene;
        this.gui = gui;
        this.camera = camera;
        this.composer = composer;
        this.renderer = renderer;
        this.width = width;
        this.height = height;

        /** Le shader décrivant la passe post traitement du rendu.
         *  @type {THREE.ShaderPass}
         */
        this.effect_ssao = null;

        /** Le nœud de l’interafce contenant les paramètres du shader. 
         * @type {GUI}
        */
        this.folder = null;

        this.setup_scene();
        //this.setup_gui();
    }

    /** Création du post traîtement */
    setup_scene()
    {
        this.effect_ssao = new SSAOPass(this.scene, this.camera, this.width, this.height);
        this.effect_ssao.kernelRadius = 3;
        this.effect_ssao.minDistance = 0.0072;
        this.effect_ssao.maxDistance = 0.3;
        this.composer.addPass(this.effect_ssao);
    }

    /** Création de l'interface permettant de manipuler la caméra. */
    setup_gui()
    {
        this.folder = this.gui.addFolder('SSAO');

        this.folder.add(this.effect_ssao, 'output', {
            'Default': SSAOPass.OUTPUT.Default,
            'SSAO Only': SSAOPass.OUTPUT.SSAO,
            'SSAO Only + Blur': SSAOPass.OUTPUT.Blur,
            'Beauty': SSAOPass.OUTPUT.Beauty,
            'Depth': SSAOPass.OUTPUT.Depth,
            'Normal': SSAOPass.OUTPUT.Normal
        }).onChange((value) =>
        {
            this.effect_ssao.output = parseInt(value);

        });

        this.folder.add(this.effect_ssao, 'kernelRadius').min(0).max(32);
        this.folder.add(this.effect_ssao, 'minDistance').min(0.001).max(0.02);
        this.folder.add(this.effect_ssao, 'maxDistance').min(0.01).max(0.3);
    }

    /** La fenêtre est redimentionnée, le shader doit s’adapater aux nouvelles
     * largeur et hauteur de la zone de rendue. 
     * @param {Int} width Largeur en pixel de la zone de rendue.
     * @param {Int} height Hauteur en pixel de la zone de rendue.
     */
    on_window_resize(width, height) 
    {}

    /** Fonction appelée lors du rendu de chaque frame.
     * @param {Float} time temps depuis le lancement de l’application en 
     * milli-secondes.
     */
    render(time)
    { }
}

export { SSAO };
