import { debug } from 'console';
import $ from 'jquery';

import * as THREE from 'three';

// Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

var assert = require('assert');

/**
* @class Défini les paramètres d’une vidéo pour être chargé dans ThreeJs.
*/
class Video
{
    constructor(name, extension, url, url_ogg, path)
    {
        /**
         * Le nom de la vidéo.
         * @type {String}
         */
        this.name = name;

        /**
        * L'extension de la vidéo.
        * @type {String}
        */
        this.extension = extension;

        /**
         * L’adresse internet de la vidéo mp4 à charger dans Threejs
         * @type {String}
         */
        this.url = url;

        /**
         * L’adresse internet de la vidéo ogv à charger dans Threejs
         * @type {String}
         */
        this.url_ogg = url_ogg;

        /**
         * L’adresse locale de la vidéo à charger dans Blender
         * @type {String}
         */
        this.path = path;
    }
}

/**
 * @class La classe qui va créer et gérer les particules qui sont en réalité des vidéos.
 */
class Particules
{
    /**
     * Constructeur de la classe Particules
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     */
    constructor(scene, gui)
    {
        this.scene = scene;
        this.gui = gui;

        /** La texture */
        this.texture = null;

        /** L’élément html qui sera utiliser pour définir l’url de la vidéo.
         * @type {HTMLElement}
        */
        this.video_source = null;

        /** L’élément html qui sera utiliser pour rendre la vidéo sélectionnée.
         * @type {HTMLElement}
        */
        this.video_element = null;

        /** Stocke la réponse de la fonction lecture.
         *  @see https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
         *  @type
         */
        this.play_promise = null;

        /** Les différentes vidéo d’effets contenu dans le dossier:
         * ./particules.
         * @type {Arry<string>}
         * @todo les récupérer automatiquement.
        */
        this.videos = {};

        /** Le nom de la sélection à choisin pour n’afficher aucune vidéo.
         * @type {String}
        */
        this.no_video_name = "Aucun";

        /** Le nom de la vidéo sélectionnée par l’utilisateur.
         * @type {String}
        */
        this.video_name = this.no_video_name;

        /** Les différents noms des vidéo d’effets contenu dans le dossier:
         * ./particules.
         * @type {Arry<string>}
         * @todo les récupérer automatiquement.
        */
        this.videos_names = this.get_video_names(); //["Aucun", "stadust"]

        /** Le nœud de l’interafce contenant les paramètres de la caméra 
         * @type {GUI}
        */
        this.folder = null;

        this.setup_scene();
        this.setup_gui();
    }

    /** Création de l’affichage d’une vidéo d’effets de particules. */
    setup_scene()
    {
        this.scene.background = this.texture;

        this.refresh();
    }

    /** Création de l'interface permettant de manipuler le texte. */
    setup_gui()
    {
        this.folder = this.gui.addFolder('Particules');

        this.folder.add(this, 'video_name', this.videos_names).name('Sélection').onChange((value) =>
        {
            this.refresh();
        });

        /*
        this.folder.add(this.group.scale, 'x', 0.1, 4).name('Échelle').onChange(() =>
        {
            this.group.scale.z = this.group.scale.y = this.group.scale.x;
        });

        const pos_folder = this.folder.addFolder('Position');
        pos_folder.add(this.group.position, 'x', -200, 200);
        pos_folder.add(this.group.position, 'y', -200, 200);
        pos_folder.add(this.group.position, 'z', -200, 200);

        const rot_folder = this.folder.addFolder('Orientation');
        rot_folder.add(this.group.rotation, 'x', -Math.PI, Math.PI);
        rot_folder.add(this.group.rotation, 'y', -Math.PI, Math.PI);
        rot_folder.add(this.group.rotation, 'z', -Math.PI, Math.PI);
        //*/

        this.folder.open();
    }

    create_video_element()
    {
        // <video id="video" loop muted crossOrigin="anonymous" playsinline style="display:none">
        //    <source src="particules/stadust.mp4">
        // </video>
        //        id="video" loop crossOrigin="anonymous" playsinline style="display:none">
        // <video id="video" loop muted crossOrigin="anonymous" playsinline style="display:none">
        //      <source src="particules/stadust.ogg">
        //	    <source src="particules/stadust.mp4">
        // </video>
        // <video id="video" loop="" crossorigin="anonymous" playsinline="" style="display:none">
        //	<source src="textures/sintel.ogv" type="video/ogg; codecs=&quot;theora, vorbis&quot;">
        //	<source src="textures/sintel.mp4" type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;">
        //</video>
        //
        //<source src="textures/sintel.ogv" type='video/ogg; codecs="theora, vorbis"'>
        //<source src="textures/sintel.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'></source>
        //   
        // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted     

        // <video muted="" crossorigin="anonymous" playsinline="" style="display: inherit;" loop=""><source src="http://127.0.0.1:8080/edsa-holo/dist/particules/stadust.mp4"></video>

        var video = document.createElement("video",);
        this.video_source = document.createElement("source");
        this.video_source.setAttribute("src", "");
        video.appendChild(this.video_source);

        video.setAttribute("loop", "");
        video.setAttribute("muted", "");
        video.setAttribute("crossOrigin", "anonymous");
        video.setAttribute("playsinline", "");

        video.style.display = "none";

        document.body.appendChild(video);

        this.video_element = video;
    }

    destroy_video_element()
    {
        document.body.removeChild(this.video_element);
        this.video_element = null;
        this.video_source = null;
    }

    create_video()
    {
        if(this.video_element)
            this.destroy_video_element();

        if(this.video_name == this.no_video_name)
        {
            this.video_source.setAttribute("src", "");
            this.scene.background = null;
        }
        else
        {
            this.create_video_element();
            
            if(false && this.video_element.canPlayType("video/mp4"))
            {
                this.video_source.setAttribute("src", this.videos[this.video_name].url);
                this.video_source.setAttribute("type", "video/mp4");
                this.video_source.setAttribute("codecs", "avc1.42E01E, mp4a.40.2");
                //this.video_source.setAttribute("codecs", "avc, mp4");
            }
            else
            {
                this.video_source.setAttribute("src", this.videos[this.video_name].url_ogg);
                this.video_source.setAttribute("type", "video/ogg");
                this.video_source.setAttribute("codecs", "theora, vorbis");
            }

            this.texture = new THREE.VideoTexture(this.video_element);
            this.scene.background = this.texture;

            //console.log("0 readyState: " + this.video_element.readyState);

            // La vidéo doit être chargée avant de demander à jouer la vidéo
            this.video_element.addEventListener('loadeddata', (e) => 
            {
                //console.log("loadeddata");
                //console.log("1 readyState: " + this.video_element.readyState);

                //Video should now be loaded but we can add a second check             
                if(this.video_element.readyState >= 3)
                {
                    try 
                    {
                        // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
                        this.play_promise = this.video_element.play();
    
                        if(this.play_promise != null)
                        {
                            this.play_promise.then(_ =>
                            {
                                //console.log("Lecture de la vidéo: " + this.video_name);
                                this.video_element.volume = 0.0;                                
                            })
                            .catch(error =>
                            {
                                console.log(error);
                            });
                        }
    
                    }
                    catch(err) 
                    {
                        // this.video_element.log(err);
                        console.log(err);
                    }
                }
            });

        }
    }

    /** Détruit la vidéo pour le reconstruire.*/
    refresh()
    {
        if(this.video_element && this.play_promise != null)
        {
            this.play_promise.then(_ =>
            {
                // https://www.sitepoint.com/community/t/uncaught-in-promise-domexception-the-fetching-process-for-the-media-resource-was-aborted-by-the-user-agent-at-the-users-request/365550/2
                this.video_element.pause();
            })
            .catch(error =>
            {
                console.log(error);
            });
        }

        if(this.video_name != this.no_video_name)
            this.create_video();
    }

    /** Retourne la liste des noms des vidéos disponibles
     * @type {Array<String>}
     */
    get_video_names()
    {
        let result = [this.no_video_name];

        let request = $.ajax({
            type: 'POST',
            url: "particules.php",
            dataType: "json",
            async: false
        });

        request.done((data) =>
        {
            /// [{  
            ///     "name":"stadust",
            ///     "extension": "mp4",
            ///     "url":"http://127.0.0.1/dist/particules/stadust.mp4",
            ///     "path":"D:\\Projets\\Holo\\www\\dist\\particules\\stadust.mp4"
            ///  },
            ///  {
            ///     "name":"stadust2",
            ///     "extension": "mp4",
            ///     "url":"http://127.0.0.1/dist/particules/stadust2.mp4",
            ///     "path":"D:\\Projets\\Holo\\www\\dist\\particules\\stadust2.mp4"
            ///  }]
            result = new Array(data.length + 1);
            result[0] = this.no_video_name;

            for(let index in data)
            {
                let video = data[index];
                let name = video.name.toString();

                // index + 1 car l’indice 0 est celui pour définir le choix: pas
                // de particules.
                result[parseInt(index) + 1] = name;

                this.videos[name] = new Video(name, video.extension.toString(), video.url.toString(), video.url_ogg.toString(), video.path.toString());
            }
        });

        request.fail((jqXHR, textStatus, errorThrown) =>
        {
            alert("Request failed: " + textStatus + ' ' + errorThrown + ' ' + jqXHR.responseText);
        });

        return result;
    }
}

export { Particules };
