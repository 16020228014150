import * as THREE from 'three';

// Pour faire des post traîtements.
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

//Documentation de l'API: https://sbcode.net/threejs/dat-gui/
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

import { CircleShader } from './shaders/circle.js';

/**
 * @class Le post traîtement qui permet de visualiser la 3D dans un cercle
 * représentant la taille de l’hélice
 */
class Circle
{
    /**
     * Constructeur de la classe Circle
     * @param {THREE.Scene} scene Scéne 3D de l’éditeur.
     * @param {GUI} gui Interface graphique de l’éditeur.
     * @param {EffectComposer} composer Le compositeur du rendu.
     * @param {Int} propeller_pixels_size
     * @param {Int} width 
     * @param {Int} height
     */
    constructor(scene, gui, composer, propeller_pixels_size, width, height)
    {
        this.scene = scene;
        this.gui = gui;
        this.composer = composer;
        this.propeller_pixels_size = propeller_pixels_size;
        this.width = width;
        this.height = height;

        /** Le shader décrivant la passe post traitement du rendu.
         *  @type {THREE.ShaderPass}
         */
        this.effect_circle = null;

        /** Le nœud de l’interafce contenant les paramètres du shader. 
         * @type {GUI}
        */
        this.folder = null;

        this.setup_scene();
        //this.setup_gui();
    }

    /** Création du post traîtement */
    setup_scene()
    {
        this.effect_circle = new ShaderPass(CircleShader);
        var effect_circle = this.effect_circle;

        effect_circle.uniforms['diameter'].value = this.propeller_pixels_size;
        effect_circle.uniforms['thickness'].value = 3;
        effect_circle.uniforms['external_color'].value = new THREE.Color("rgb(7, 7, 6)");
        effect_circle.uniforms["resolution"].value = new THREE.Vector2(this.width, this.height);
        this.composer.addPass(effect_circle);
    }

    /** Création de l'interface permettant de manipuler le shader. */
    setup_gui()
    {
        this.folder = this.gui.addFolder('Cercle');

        const uniforms = this.effect_circle.uniforms;

        this.folder.add(this.effect_circle, 'enabled').name("Activation");
        this.folder.add(uniforms.diameter, 'value', 1, window.innerHeight).name("Rayon");
        this.folder.add(uniforms.thickness, 'value', 0, 20).name("Épaisseur");
        this.folder.addColor(uniforms.external_color, 'value').name("Couleur extérieur");
    }

    /** La fenêtre est redimentionnée, le shader doit s’adapater aux nouvelles
     * largeur et hauteur de la zone de rendue. 
     * @param {Int} width Largeur en pixel de la zone de rendue.
     * @param {Int} height Hauteur en pixel de la zone de rendue.
     */
    on_window_resize(width, height) 
    {
        this.effect_circle.uniforms["resolution"].value.set(width, height).multiplyScalar(window.devicePixelRatio);
    }

    /** Fonction appelée lors du rendu de chaque frame.
     * @param {Float} time temps depuis le lancement de l’application en 
     * milli-secondes.
     */
    render(time)
    {
        this.effect_circle.uniforms["time"].value = time;
    }

}

export { Circle };
